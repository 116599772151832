import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const AUTH_FEATURE_NAME = 'auth';
export interface AuthState {
  isLoggedIn: boolean,
  phoneNumber: string,
  lastTryTime: number
  stateChangeEnable: boolean,
  code: string
}

export const signInInitialState: AuthState = {
  isLoggedIn: false,
  phoneNumber: '',
  lastTryTime: 0,
  stateChangeEnable: true,
  code: ''
};

export const authReducer = createReducer(
  signInInitialState,
  on(AuthActions.signIn, (state) => ({ ...state, stateChangeEnable: false})),
  on(AuthActions.signInWithTelegram, (state) => ({ ...state, stateChangeEnable: false})),
  on(AuthActions.signOutSuccess, (state) => ({ ...state,
    stateChangeEnable: false,
    isLoggedIn:false,
    token: null,
    lastTryTime: Date.now()
  })),
  on(AuthActions.signInSuccess, (state, { payload }) => ({ ...state,
    stateChangeEnable: true,
    token: payload?.token,
    lastTryTime: Date.now()
  })),
  on(AuthActions.signInFailure, (state) => ({ ...state,
    stateChangeEnable: true,
    lastTryTime: Date.now()
  })),
  on(AuthActions.signUp, (state,{ signUpInput }) => ({ ...state,
    stateChangeEnable: false,
    lastTryTime: Date.now(),
    phoneNumber:  signUpInput?.phoneNumber
  })),
  on(AuthActions.signUpSuccess, (state) => ({ ...state,
    stateChangeEnable: true
  })),
  on(AuthActions.signUpFailure, (state) => ({ ...state,
    stateChangeEnable: true
  })),
  on(AuthActions.resetPasswordRequestStart, (state, {resetPasswordInput }) => ({ ...state,
    phoneNumber: resetPasswordInput.phoneNumber
  })),
  on(AuthActions.resetPasswordRequestSuccess, (state) => ({ ...state,})),
  on(AuthActions.verifyPhoneNumber, (state, {verifyInput}) => ({ ...state,
    code: verifyInput.tempCode
  })),
  on(AuthActions.fetchSessionSuccess, (state, { session }) => ({
    ...state,
    session,
    error: null
})),
  on(AuthActions.fetchSessionFailure, (state, { error }) => ({
    ...state,
    session: null,
    error
  }))
);
export function sessionReducer(state: any, action: any) {
  return authReducer(state, action);
}


export function auth(state: AuthState , action: Action) {
  return authReducer(state, action);
}

export const selectAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_NAME);
export const selectIsChangeEnabled = createSelector(
  selectAuthState,
  (state: AuthState) => state.stateChangeEnable
);

export const selectCheckPhone = createSelector(
  selectAuthState,
  (state: AuthState) => state.phoneNumber
);

export const selectCode = createSelector(
  selectAuthState,
  (state: AuthState) => state.code
);
