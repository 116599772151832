import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import {StorageKeys, StorageService} from '@revolve-app/app/core/utils/storage.service';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AuthService} from "@revolve-app/app/core/features/auth/services/auth.service";
import {updateUserSession} from "@revolve-app/app/core/features/accounts/store/me/me.actions";
import {SocketService} from "@revolve-app/app/core/features/socket/services/socket.service";
import {socketReconnect} from "@revolve-app/app/core/features/socket/store/socket/socket.actions";

@Injectable()
export class AuthEffect {
  signIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signIn),
      mergeMap((action) => {
          return this.authService.signIn(action.signInInput)
            .pipe(
              map(payload => {
                return AuthActions.signInSuccess({ payload ,  redirectUrl:action.redirectUrl})
              }),
              catchError(error => {
                return of(AuthActions.signInFailure())
              })
            )
        }
      )
    )
  });
  resendVerifyPhoneNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resendVerifyPhoneNumber),
      mergeMap((action) => {
          return this.authService.resendVerifyPhoneNumber({phoneNumber: action.phoneNumber})
            .pipe(
              map(payload => {
                action.callback && action.callback()
                return AuthActions.resendVerifyPhoneNumberSuccess()
              }),
              catchError(error => {
                return of(AuthActions.resendVerifyPhoneNumberFailure())
              })
            )
        }
      )
    )
  });

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signOut),
      mergeMap((action) => {
          return this.authService.logout()
            .pipe(
              map(payload => {
                return AuthActions.signOutSuccess()
              })
            )
        }
      )
    )
  });
  signOutSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signOutSuccess),
      tap((action) => {
          StorageService.removeItem(StorageKeys.USEROBJECT);
          StorageService.removeItem(StorageKeys.SESSION);
          StorageService.removeItem(StorageKeys.TOKEN);
          this.storeService.dispatch(updateUserSession({session: null, user: null}));
          this.routerService.navigate(['/']);
          window.location.reload();
        }
      )
    )
  }, {dispatch: false});

  signInSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.signInSuccess),
        tap((action) => {
            if(action?.payload?.token){
              StorageService.setItem(StorageKeys.TOKEN, action?.payload?.token);
              StorageService.setJsonItem(StorageKeys.SESSION,  action?.payload.session);
              this.routerService.navigate([action.redirectUrl], { queryParams: action.queryParams });
              this.storeService.dispatch(socketReconnect());
              this.storeService.dispatch(updateUserSession({session: action?.payload.session, user: action.payload}));
            }
          }
        )
      ),
    { dispatch: false }

  );

  signUp$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.signUp),
    mergeMap((action) =>
    this.authService.signUp(action.signUpInput).pipe(
        map(payload => {
          this.routerService.navigate([action.redirectUrl], { queryParams: action.queryParams });
          return  AuthActions.signUpSuccess({payload, phoneNumber: action.signUpInput.phoneNumber})
          }

        ),
        catchError(error => of(AuthActions.signUpFailure()))
      )

    )
  ))

  signUpSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.signUpSuccess),
        tap((action) => {
            StorageService.setItem(StorageKeys.PHONE_NUMBER, action?.phoneNumber);
          }
        )
      ),
    { dispatch: false } // Set dispatch to false to prevent this effect from dispatching actions

  );




  signInWithTelegram$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.signInWithTelegram),
    mergeMap((action) => {
        return this.authService.signInWithTelegram(action.signInInput)
          .pipe(
            map(payload => {
              return AuthActions.signInSuccess({ payload , redirectUrl:action.redirectUrl})
            }),
            catchError(error => {
              return of(AuthActions.signInFailure())
            })
          )
      }
    )
  ));



  verifyEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyEmail),
      mergeMap((action) => {
          return this.authService.verifyEmail(action.verifyInput).pipe(
            map(payload => {
             return AuthActions.signInSuccess({ payload , redirectUrl:action.redirectUrl})
            }),
            catchError(error => of(AuthActions.verifyEmailFailure()))
          )
        }
      )
    )
  });

  verifyPhoneNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyPhoneNumber),
      mergeMap((action) => {
          return this.authService.verifyPhoneNumber(action.verifyInput).pipe(
            map(payload => {
              this.routerService.navigate([action.redirectUrl]);
              StorageService.removeItem(StorageKeys.PHONE_NUMBER);
              return AuthActions.signInSuccess({ payload , redirectUrl:action.redirectUrl})
            }),
            catchError(error => of(AuthActions.verifyPhoneNumberFailure()))
          )
        }
      )
    )
  });

  checkForgotCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.checkForgotCode),
      mergeMap((action) => {
          return this.authService.checkCode(action.verifyInput).pipe(
            map(payload => {
              StorageService.setItem(StorageKeys.VERIFICATION_CODE, action.verifyInput.tempCode);
              this.routerService.navigate([action.redirectUrl]);
              return AuthActions.verifyPhoneNumberSuccess({ payload , redirectUrl:action.redirectUrl})
            }),
            catchError(error => of(AuthActions.verifyPhoneNumberFailure()))
          )
        }
      )
    )
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPasswordRequestStart),
      mergeMap((action) => {
          return this.authService.resetPasswordStep1(action.resetPasswordInput).pipe(
            map((payload) => {
              this.routerService.navigate([action.redirectUrl]);
              return AuthActions.resetPasswordRequestSuccess({ payload , redirectUrl:action.redirectUrl, phoneNumber: action.resetPasswordInput.phoneNumber})
            }),
            catchError(error => of(AuthActions.resetPasswordRequestFail()))
          )
        }
      )
    )
  });

  resetPasswordRequestSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.resetPasswordRequestSuccess),
        tap((action) => {
            StorageService.setItem(StorageKeys.PHONE_NUMBER, action?.phoneNumber);
          }
        )
      ),
    { dispatch: false } // Set dispatch to false to prevent this effect from dispatching actions

  );

  setNewPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setNewPasswordRequestStart),
      mergeMap((action) => {
          return this.authService.resetPasswordStep2(action.setNewPasswordInput).pipe(
            map((payload) => {
              this.routerService.navigate([action.redirectUrl]);
              StorageService.removeItem(StorageKeys.PHONE_NUMBER);
              StorageService.removeItem(StorageKeys.VERIFICATION_CODE);
              return AuthActions.setNewPasswordRequestSuccess({ payload , redirectUrl:action.redirectUrl})
            }),
            catchError(error => of(AuthActions.setNewPasswordRequestFail()))
          )
        }
      )
    )
  });

  fetchSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.fetchSession),
      mergeMap((action) => {
        return this.authService.getSession().pipe(
          map((session) => {
            return AuthActions.fetchSessionSuccess({ session });
          }),
          catchError((error) => of(AuthActions.fetchSessionFailure({ error })))
        );
      })
    );
  });
  saveSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.fetchSessionSuccess),
        tap((action) => {
          localStorage.setItem('session', JSON.stringify(action.session));
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private storeService: Store,
    private routerService: Router,
    private authService: AuthService,
  ) {}
}
