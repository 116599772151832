import { NgModule } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {AuthService} from "@revolve-app/app/core/features/auth/services/auth.service";
import {AUTH_FEATURE_NAME, authReducer} from "@revolve-app/app/core/features/auth/store/auth/auth.reducer";
import {AuthEffect} from "@revolve-app/app/core/features/auth/store/auth/auth.effect";

@NgModule({
  imports: [
  ],
  providers: [
    AuthService,
    provideState(AUTH_FEATURE_NAME, authReducer),
    provideEffects(AuthEffect)
  ]
})
export class AuthModule { }
