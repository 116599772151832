import {Injectable} from '@angular/core';
import {HttpService} from "@revolve-app/app/core/services/network/http/http.service";
import {
  TNewPasswordRequest, TResendVerificationCodeRequest,
  TResetPasswordRequest,
  TSignInRequest, TSignInSuccessResponse,
  TSignupRequest,
  TVerifyEmailRequest, TVerifyPhoneNumberRequest
} from "@revolve-app/app/core/metadata/types/auth.models";
import {Observable} from "rxjs";
import {TBaseSuccessResponse} from "@revolve-app/app/core/metadata/types/base.models";
import {MicroService} from "@revolve/meta-data/metadata/enums/MicroService";
import {env} from "@revolve-app/env/env";

@Injectable()
export class AuthService{

  private baseUrl = `api/gateway/${MicroService.ACCOUNT}/${env.backendVersion}`;

  constructor(private httpService: HttpService) {
  }
  signIn(signInInput: TSignInRequest): Observable<TSignInSuccessResponse>{
    return this.httpService.post(`${this.baseUrl}/auth/sign-in`, signInInput)
  }
  signInWithTelegram(signInInput: TSignInRequest): Observable<TSignInSuccessResponse>{
    return this.httpService.post(`${this.baseUrl}/auth/sign-in-with-telegram`, signInInput)
  }

  signUp(signUnInput: TSignupRequest): Observable<TBaseSuccessResponse>{
    return this.httpService.post(`${this.baseUrl}/auth/sign-up`, signUnInput)
  }

  verifyEmail(verificationInput: TVerifyEmailRequest): Observable<TSignInSuccessResponse>{
    return this.httpService.post(`${this.baseUrl}/verify-email`, verificationInput)
  }

  verifyPhoneNumber(verificationInput: TVerifyPhoneNumberRequest): Observable<TSignInSuccessResponse>{
    return this.httpService.post(`${this.baseUrl}/auth/verify-phone`, verificationInput)
  }

  checkCode(verificationInput: TVerifyPhoneNumberRequest){
    return this.httpService.put(`${this.baseUrl}/auth/check-code`, verificationInput)
  }

  resetPasswordStep1(resetPasswordRequest: TResetPasswordRequest){
    return this.httpService.post(`${this.baseUrl}/auth/reset-password`, resetPasswordRequest)
  }

  resetPasswordStep2(newPasswordRequest: TNewPasswordRequest){
    return this.httpService.post(`${this.baseUrl}/auth/new-password`, newPasswordRequest)
  }

  logout(){
    return this.httpService.post(`${this.baseUrl}/auth/logout`, {})
  }

  getSession(){
    return this.httpService.get(`${this.baseUrl}/auth/get-session`)
  }

  resendVerifyPhoneNumber(data: TResendVerificationCodeRequest){
    return this.httpService.post(`${this.baseUrl}/auth/resend-verification-code`, data)
  }

}
